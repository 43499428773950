<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "MyAccount",

  metaInfo() {
    return {
      title: this.$t("CalendarSP.title"),
      titleTemplate: (titleChunk) => {
        return titleChunk
          ? `${titleChunk} - ${this.$t("meta.titleTemplate")}`
          : this.$t("meta.titleTemplate");
      },
    };
  },

  extends: View,

  mixins: [LoadSections(["serviceprovider/sections/serviceprovider-calendar"])],

  props: {
    id: {
      type: String,
      default: "proagenda",
    },
  },
};
</script>
